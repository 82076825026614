import React from "react"
import {useState, useEffect} from 'react';
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { TransitionPortal } from 'gatsby-plugin-transition-link';


import "../../assets/styles/headerstyle.scss"
import devText from "../../assets/images/develoscopeblue-text.png"
import devIcon from "../../assets/images/develoscopeblue.png"
import instagramIcon from "../../assets/images/instagram-icon.svg"

const Header = (current) => {

    const currentPage = current.current;

    const [scrolled,setScrolled]=React.useState(false);
    const handleScroll=() =>{
      const offset=window.scrollY;
      if(offset > 75 ){
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll)
    })
    let navbarClasses=['navigation'];
    if(scrolled){
      navbarClasses.push('nav-stick');
    }
  

    const [hamBurger, setHamBurger] = useState(0);

    function menuState(){
      if(hamBurger == 1){
  
        return "active"
      } else if(hamBurger == 0) {
        return ""
      }
    }

    return(
        <TransitionPortal>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Develoscope - software solutions for your burning ideas!</title>
          <link rel="icon" type="image/png" href="/favicon.ico" sizes="16x16" />
          <meta name="description" content="Precisely matching technology to the needs of your business is our core expertise. We crafted our suite of services to be flexible and responsive. Our entire focus is delivering fast, impactful results in your digital initiatives." />
          <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
          />
          <link rel="canonical" href="https://develoscope.com/" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Develoscope - software solutions for your burning ideas!" />
          <meta property="og:description" content="Precisely matching technology to the needs of your business is our core expertise. We crafted our suite of services to be flexible and responsive." />
          <meta property="og:url" content="https://develoscope.com/" />
          <meta property="og:site_name" content="Develoscope" />
          <meta property="og:image" content="https://develoscope.com/develescope-image.jpg" />
          <meta property="og:image:secure_url" content="https://develoscope.com/develescope-image.jpg" />
          <meta property="og:image:width" content="1280" />
          <meta property="og:image:height" content="720" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:description" content="Precisely matching technology to the needs of your business is our core expertise. We crafted our suite of services to be flexible and responsive." />
          <meta name="twitter:title" content="Develoscope - software solutions for your burning ideas!" />
          <meta name="twitter:image" content="https://develoscope.com/develescope-image.jpg" />
        </Helmet>
        <nav className={navbarClasses.join(" ")}>
            <Link to="/">
                <a><div className="img">
                <img src={devIcon} alt="Develoscope-Icon" />
                <img src={devText} alt="Develoscope" />
                </div></a>
            </Link>
            <div className={"hamburger " + menuState()} onClick={() => hamBurger == 0 ? setHamBurger(1) : setHamBurger(0)}>
                <span className="line line-1"></span>
                <span className="line line-2"></span>
                <span className="line line-3"></span>
            </div>
            <div className="nav-items">
                <ul className={menuState()}>
                    <li>
                    <AniLink to="/" swipe direction="left">
                        <a className ={currentPage === "home" ? 'active' : ''}>Home</a>
                    </AniLink>
                    </li>
                    <li>
                    <AniLink to="/expertise" swipe direction="right">
                        <a className ={currentPage === "expertise" ? 'active' : ''}>Expertise</a>
                    </AniLink>
                    </li>
                    <li>
                    <AniLink to="/projects" swipe direction="right">
                        <a className ={currentPage === "projects" ? 'active' : ''}>Projects</a>
                    </AniLink>
                    </li>
                    <li>
                    <AniLink to="/team" swipe direction="right">
                        <a className ={currentPage === "team" ? 'active' : ''}>Our Team</a>
                    </AniLink>
                    </li>
                    <li>
                    <AniLink to="/blogs" swipe direction="right">
                        <a className ={currentPage === "blog" ? 'active' : ''}>Blogs</a>
                    </AniLink>
                    </li>
                    <li>
                    <AniLink to="/contact" swipe direction="right">
                        <a className ={currentPage === "contact" ? 'active' : ''}>Contact us</a>
                    </AniLink>
                    </li>
                    <li className="instagram-wrap">           
                          <img src={instagramIcon} alt="Instagram" className="instagram-icon"/>
                    </li>
                </ul>
            </div>
        </nav>
        </TransitionPortal>
    )
}

export default Header;