import React from 'react'

import "../../assets/styles/footerstyle.scss"

import twitterIcon from "../../assets/images/twitter.svg"
import facebookIcon from "../../assets/images/facebook.svg"
import instagramIcon from "../../assets/images/instagram.svg"
import laptopContact from "../../assets/images/laptop-contact.jpg"

export default function Footer(current) {

    const currentPage = current.current;

    return (
        <>
        <section className={currentPage === "contact" ? "footer-main contact" : "footer-main"} style={{ display: currentPage === "blogpost" ? 'none' : ''}}>
            
            <div className="footer-right">
                <div className="flex-wrap">
                    <h6>location</h6>
                    <p>Address line 1</p>
                    <p>Address line 2</p>
                    <p>City/State/Zip</p>
                    <p>Country</p>
                </div>
            </div>
            
            <div className="footer-left">
                <div className="flex-wrap">
                    <div className="wrap">
                    <h4>Get in touch</h4>
                    <h3>Need our help building or managing  an app.  Hit us up!</h3>
                    <div className="form">
                        <div className="columns">
                            <div className="column is-6">
                                <div class="field">
                                    <div class="control">
                                        <input class="input" type="email" placeholder="Email Address"/>
                                    </div>
                                    <div class="control">
                                        <input class="input" type="text" placeholder="Subject"/>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-6">
                                <div class="control">
                                    <textarea class="textarea" placeholder="Your Message"></textarea>
                                </div>
                                <div class="control">
                                    <button class="button is-link">Submit</button>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
                
            </div>
        </section>
        <section className="bottom">
            <div><p>2020 Develoscope. All Rights Reserved.</p></div>
            <div className="bott-icons">
                <p><a href="mailto: info@develoscope.com">info@develoscope.com</a></p>
                <img src={facebookIcon} alt=""/>
                <img src={twitterIcon} alt=""/>
                <img src={instagramIcon} alt=""/>
            </div>
        </section>
        </>
    )
}
